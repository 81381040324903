<script setup>
import { computed } from "vue";

const props = defineProps({
  class: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const delegatedProps = computed(() => {
  // eslint-disable-next-line no-unused-vars
  const { class: _, ...rest } = props;
  return rest;
});
</script>

<template>
  <label v-bind="delegatedProps" :aria-disabled="props.disabled || undefined" class="label">
    <slot />
  </label>
</template>

<style scoped>
.label {
  line-height: 200%;
  color: black;
  margin-bottom: 3px;
}

.label[aria-disabled="true"] {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
