<script setup>
import { computed, watch } from "vue";
import { useField } from "vee-validate";
import { ErrorMessage } from "..";
import { validationModes } from "../validation-modes";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "text",
  },
  validationMode: {
    type: String,
    default: "eager",
  },
});

const emit = defineEmits(["changed"]);

const { value, errorMessage, meta, handleChange, handleBlur } = useField(
  props.name,
  {},
  {
    validateOnValueUpdate: false,
  }
);

watch(value, (newValue, oldValue) => {
  emit("changed", { newValue, oldValue });
});

const handlers = computed(() => {
  const on = {
    blur: handleBlur,
    input: [(e) => handleChange(e, false)],
    change: undefined,
  };

  const triggers = validationModes[props.validationMode]({
    errorMessage,
    meta,
  });

  triggers.forEach((trigger) => {
    if (Array.isArray(on[trigger])) {
      on[trigger].push(handleChange);
    } else {
      on[trigger] = handleChange;
    }
  });

  return on;
});
</script>

<template>
  <div>
    <FormInputLabel v-if="props.label?.length" :for="props.name">
      {{ props.label }}
    </FormInputLabel>
    <div class="relative">
      <InputText
        v-on="handlers"
        v-model="value"
        :type="props.type"
        :placeholder="props.placeholder"
        :autocomplete="props.autocomplete"
        :disabled="props.disabled"
        :class="[errorMessage?.length && 'error-state', 'input']"
      />
    </div>
    <ErrorMessage :message="errorMessage" />
  </div>
</template>

<style scoped>
.input {
  width: 100%;
}
.error-state {
  border-color: red;
  &:hover {
    border-color: red;
  }
}
</style>
