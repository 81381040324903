<script setup>
import { computed, watch, ref } from "vue";
import { useField } from "vee-validate";
import { ErrorMessage } from "..";
import { validationModes } from "../validation-modes";

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  optionLabel: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  disabled: {
    type: Boolean,
  },
  validationMode: {
    type: String,
    default: "eager",
  },
});

const {
  value: fieldValue,
  errorMessage,
  meta,
  handleChange,
  handleBlur,
} = useField(
  props.name,
  {},
  {
    validateOnValueUpdate: true,
  }
);

const model = ref(() => String(fieldValue.value) || "");
watch(model, (newValue) => {
  fieldValue.value = { ...fieldValue.value, value: newValue };
});

watch(fieldValue, (newValue) => {
  if (model.value !== newValue.value && typeof newValue.value === "string") {
    model.value = newValue.value;
  }
});

const handlers = computed(() => {
  const on = {
    blur: handleBlur,
    input: [(e) => handleChange(e, false)],
    change: undefined,
  };

  const triggers = validationModes[props.validationMode]({
    errorMessage,
    meta,
  });

  triggers.forEach((trigger) => {
    if (Array.isArray(on[trigger])) {
      on[trigger].push(handleChange);
    } else {
      on[trigger] = handleChange;
    }
  });

  return on;
});
</script>

<template>
  <div>
    <FormInputLabel v-if="props.label?.length" :for="props.name">
      {{ props.label }}
    </FormInputLabel>
    <div class="relative">
      <Dropdown
        v-on="handlers"
        v-model="model"
        :name="props.name"
        :optionLabel="props.optionLabel"
        :options="props.options"
        :placeholder="props.placeholder"
        :autocomplete="props.autocomplete"
        :disabled="props.disabled"
        :class="[errorMessage?.length && 'error-state', 'dropdown']"
      />
    </div>
    <ErrorMessage :message="errorMessage" />
  </div>
</template>

<style scoped>
.dropdown {
  width: 100%;
}
.error-state {
  border-color: red;
  &:hover {
    border-color: red;
  }
}
</style>
