const passive = () => [];

const lazy = () => {
  return ["change"];
};

const aggressive = () => ["input"];

const eager = ({ errorMessage }) => {
  if (errorMessage.value) {
    return ["input"];
  }

  return ["change"];
};

export const validationModes = {
  passive,
  lazy,
  aggressive,
  eager,
};
