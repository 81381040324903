<template>
  <div class="p-fileupload p-fileupload-basic p-component">
    <FileUploadMessage
      v-for="msg of messages"
      severity="error"
      :key="msg"
      @close="onMessageClose"
      >{{ msg }}</FileUploadMessage
    >
    <span
      :class="basicChooseButtonClass"
      :style="style"
      @mouseup="onBasicUploaderClick"
      @keydown.enter="choose"
      @focus="onFocus"
      @blur="onBlur"
      v-ripple
      tabindex="0"
    >
      <span :class="basicChooseButtonIconClass"></span>
      <span class="p-button-label">{{ basicChooseButtonLabel }}</span>
      <input
        ref="fileInput"
        type="file"
        :accept="accept"
        :disabled="disabled"
        :multiple="multiple"
        @change="onFileSelect"
        @focus="onFocus"
        @blur="onBlur"
      />
    </span>
    <div class="p-fileupload-row" v-for="(file, index) in files" :key="index">
      <div class="p-fileupload-filename">{{ file.name }}</div>
      <div>
        <button class="p-button p-button-icon" @click="remove(index)">
          <i class="pi pi-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "primevue/message";
import { DomHandler } from "primevue/utils";
import Ripple from "primevue/ripple";

export default {
  name: "LocalFileUpload",
  emits: ["select", "upload", "error", "clear", "remove"],
  props: {
    name: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxFileSize: {
      type: Number,
      default: null,
    },
    invalidFileSizeMessage: {
      type: String,
      default: "{0}: Invalid file size, file size should be smaller than {1}.",
    },
    invalidFileTypeMessage: {
      type: String,
      default: "{0}: Invalid file type, allowed file types: {1}.",
    },
    fileLimit: {
      type: Number,
      default: null,
    },
    invalidFileLimitMessage: {
      type: String,
      default: "Maximum number of files exceeded, limit is {0} at most.",
    },
    style: null,
    class: null,
  },
  duplicateIEEvent: false,
  data() {
    return {
      cancelled: false,
      uploadedFileCount: 0,
      files: [],
      messages: [],
      focused: false,
      progress: null,
    };
  },
  methods: {
    onFileSelect(event) {
      if (event.type !== "drop" && this.isIE11() && this.duplicateIEEvent) {
        this.duplicateIEEvent = false;
        return;
      }
      this.messages = [];
      this.files = [];
      let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;

      for (let file of files) {
        if (!this.isFileSelected(file)) {
          if (this.validate(file)) {
            this.files.push(file);
          }
        }
      }
      this.$emit("select", { files: this.files });
      if (this.fileLimit) {
        this.checkFileLimit();
      }
      if (event.type !== "drop" && this.isIE11()) {
        this.clearIEInput();
      } else {
        this.clearInputElement();
      }
    },
    choose() {
      this.$refs.fileInput.click();
    },
    upload() {
      console.log("uploaded files:");
      console.log(this.files);
      if (this.files[0]) {
        var formData = new FormData();
        for (let file of this.files) {
          formData.append(file);
        }
        this.$emit("upload", { formData: formData });
      }
    },
    clear() {
      this.files = [];
      this.messages = null;
      this.$emit("clear");
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    isFileSelected(file) {
      if (this.files && this.files.length) {
        for (let sFile of this.files) {
          if (sFile.name + sFile.type + sFile.size === file.name + file.type + file.size)
            return true;
        }
      }
      return false;
    },
    isIE11() {
      return !!window["MSInputMethodContext"] && !!document["documentMode"];
    },
    validate(file) {
      if (this.accept && !this.isFileTypeValid(file)) {
        this.messages.push(
          this.invalidFileTypeMessage.replace("{0}", file.name).replace("{1}", this.accept)
        );
        return false;
      }
      if (this.maxFileSize && file.size > this.maxFileSize) {
        this.messages.push(
          this.invalidFileSizeMessage
            .replace("{0}", file.name)
            .replace("{1}", this.formatSize(this.maxFileSize))
        );
        return false;
      }
      return true;
    },
    isFileTypeValid(file) {
      let acceptableTypes = this.accept.split(",").map((type) => type.trim());
      for (let type of acceptableTypes) {
        let acceptable = this.isWildcard(type)
          ? this.getTypeClass(file.type) === this.getTypeClass(type)
          : file.type == type ||
            this.getFileExtension(file).toLowerCase() === type.toLowerCase();
        if (acceptable) {
          return true;
        }
      }
      return false;
    },
    getTypeClass(fileType) {
      return fileType.substring(0, fileType.indexOf("/"));
    },
    isWildcard(fileType) {
      return fileType.indexOf("*") !== -1;
    },
    getFileExtension(file) {
      return "." + file.name.split(".").pop();
    },
    onDragEnter(event) {
      if (!this.disabled) {
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragOver(event) {
      if (!this.disabled) {
        DomHandler.addClass(this.$refs.content, "p-fileupload-highlight");
        event.stopPropagation();
        event.preventDefault();
      }
    },
    onDragLeave() {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, "p-fileupload-highlight");
      }
    },
    onDrop(event) {
      if (!this.disabled) {
        DomHandler.removeClass(this.$refs.content, "p-fileupload-highlight");
        event.stopPropagation();
        event.preventDefault();
        const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
        const allowDrop = this.multiple || (files && files.length === 1);
        if (allowDrop) {
          this.onFileSelect(event);
        }
      }
    },
    onBasicUploaderClick() {
      this.$refs.fileInput.click();
    },
    remove(index) {
      let removedFile = this.files.splice(index, 1)[0];
      this.files = [...this.files];
      this.$emit("remove", {
        file: removedFile,
        files: this.files,
      });
    },
    clearInputElement() {
      this.$refs.fileInput.value = "";
    },
    clearIEInput() {
      if (this.$refs.fileInput) {
        this.duplicateIEEvent = true; //IE11 fix to prevent onFileChange trigger again
        this.$refs.fileInput.value = "";
      }
    },
    formatSize(bytes) {
      if (bytes === 0) {
        return "0 B";
      }
      let k = 1000,
        dm = 3,
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    isFileLimitExceeded() {
      if (
        this.fileLimit &&
        this.fileLimit <= this.files.length + this.uploadedFileCount &&
        this.focused
      ) {
        this.focused = false;
      }
      return this.fileLimit && this.fileLimit < this.files.length + this.uploadedFileCount;
    },
    checkFileLimit() {
      if (this.isFileLimitExceeded()) {
        this.messages.push(
          this.invalidFileLimitMessage.replace("{0}", this.fileLimit.toString())
        );
      }
    },
    onMessageClose() {
      this.messages = null;
    },
  },
  computed: {
    isAdvanced() {
      return this.mode === "advanced";
    },
    isBasic() {
      return this.mode === "basic";
    },
    advancedChooseButtonClass() {
      return [
        "p-button p-component p-fileupload-choose",
        this.class,
        {
          "p-disabled": this.disabled,
          "p-focus": this.focused,
        },
      ];
    },
    basicChooseButtonClass() {
      return [
        "p-button p-component p-fileupload-choose",
        this.class,
        {
          "p-fileupload-choose-selected": this.hasFiles,
          "p-disabled": this.disabled,
          "p-focus": this.focused,
        },
      ];
    },
    basicChooseButtonIconClass() {
      return [
        "p-button-icon p-button-icon-left pi",
        {
          "pi-plus": !this.hasFiles,
          "pi-upload": this.hasFiles,
        },
      ];
    },
    basicChooseButtonLabel() {
      return this.hasFiles ? this.files.map((f) => f.name).join(", ") : this.chooseButtonLabel;
    },
    hasFiles() {
      return !!this.files[0];
    },
    chooseDisabled() {
      return (
        this.disabled ||
        (this.fileLimit && this.fileLimit <= this.files.length + this.uploadedFileCount)
      );
    },
    uploadDisabled() {
      return (
        this.disabled ||
        !this.hasFiles ||
        (this.fileLimit && this.fileLimit < this.files.length)
      );
    },
    cancelDisabled() {
      return this.disabled || !this.hasFiles;
    },
    chooseButtonLabel() {
      return this.$primevue.config.locale.choose;
    },
    uploadButtonLabel() {
      return this.uploadLabel || this.$primevue.config.locale.upload;
    },
    cancelButtonLabel() {
      return this.cancelLabel || this.$primevue.config.locale.cancel;
    },
  },
  components: {
    FileUploadMessage: Message,
  },
  directives: {
    ripple: Ripple,
  },
  watch: {
    cancelled(newVal, oldVal) {
      console.log("cancelled", newVal, !oldVal);
      if (newVal && !oldVal) {
        this.files = [];
        this.clear();
      }
    },
  },
};
</script>

<style>
.p-fileupload-content {
  position: relative;
}
.p-fileupload-row {
  display: flex;
  align-items: center;
}
.p-fileupload-row > div {
  flex: 1 1 auto;
  width: 25%;
}
.p-fileupload-row > div:last-child {
  text-align: right;
}
.p-fileupload-content .p-progressbar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.p-button.p-fileupload-choose {
  position: relative;
  overflow: hidden;
}
.p-button.p-fileupload-choose input[type="file"] {
  display: none;
}
.p-fileupload-choose.p-fileupload-choose-selected input[type="file"] {
  display: none;
}
.p-fileupload-filename {
  word-break: break-all;
}
.p-fluid .p-fileupload .p-button {
  width: auto;
}
</style>
