<script setup>
import { computed, onMounted, watch, ref } from "vue";
import { useField } from "vee-validate";
import { validationModes } from "../validation-modes";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "text",
  },
  validationMode: {
    type: String,
    default: "eager",
  },
  onLabel: {
    type: String,
    required: true,
  },
  offLabel: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["changed"]);

const {
  value: fieldValue,
  errorMessage,
  meta,
  handleChange,
  handleBlur,
} = useField(
  props.name,
  {},
  {
    validateOnValueUpdate: false,
  }
);

const model = ref(false);
watch(model, (newValue) => {
  fieldValue.value = newValue;
});

watch(fieldValue, (newValue, oldValue) => {
  emit("changed", { newValue, oldValue });

  if (fieldValue.value !== model.value) {
    model.value = newValue;
  }
});

const handlers = computed(() => {
  const on = {
    blur: handleBlur,
    input: [(e) => handleChange(e, false)],
    change: undefined,
  };

  const triggers = validationModes[props.validationMode]({
    errorMessage,
    meta,
  });

  triggers.forEach((trigger) => {
    if (Array.isArray(on[trigger])) {
      on[trigger].push(handleChange);
    } else {
      on[trigger] = handleChange;
    }
  });

  return on;
});
</script>

<template>
  <div>
    <div class="relative">
      <ToggleButton
        v-on="handlers"
        v-model="model"
        :onLabel="props.onLabel"
        :offLabel="props.offLabel"
        :disabled="props.disabled"
        :class="['button']"
      />
    </div>
  </div>
</template>

<style scoped>
.button {
  width: 100%;
}
</style>
