<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div v-if="isAdmin()" class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";
import store from "./store";
export default {
  data() {
    return {
      itemClick: false,
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Home",
          items: [
            {
              label: "Dashboard",
              icon: "pi pi-fw pi-home",
              to: "/",
            },
          ],
        },
        {
          label: "Pages",
          icon: "pi pi-fw pi-clone",
          items: [
            { label: "Users", icon: "pi pi-fw pi-user-edit", to: "/user" },
            { label: "Abuse", icon: "pi pi-fw pi-user-edit", to: "/abuse" },
            { label: "Challenges", icon: "pi pi-fw pi-user-edit", to: "/challenge" },
            { label: "Shared practice", icon: "pi pi-fw pi-user-edit", to: "/shared-practice" },
            { label: "News Feed", icon: "pi pi-fw pi-user-edit", to: "/news-feed" },
            { label: "Promo code", icon: "pi pi-fw pi-user-edit", to: "/promo-code" },
            { label: "Gift code", icon: "pi pi-fw pi-user-edit", to: "/gift-code" },
            { label: "Firmware", icon: "pi pi-fw pi-user-edit", to: "/firmware" },
            { label: "Mass mailing", icon: "pi pi-fw pi-user-edit", to: "/mass-mailing" },
            { label: "Notifications", icon: "pi pi-fw pi-user-edit", to: "/notifications" },
            { label: "Dry practice", icon: "pi pi-fw pi-user-edit", to: "/dry-practice" },
            {
              label: "Dry practice templates",
              icon: "pi pi-fw pi-user-edit",
              to: "/dry-practice-templates",
            },
            {
              label: "Default Exercise",
              icon: "pi pi-fw pi-stopwatch",
              to: "/default-exercise",
            },
            { label: "Trash", icon: "pi pi-fw pi-trash", to: "/trash" },
            { label: "Logs", icon: "pi pi-fw pi-comments", to: "/logs" },
            { label: "Chat", icon: "pi pi-fw pi-comments", to: "/chat" },
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    isAdmin() {
      return store.state.user.isAdmin;
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
      this.itemClick = this.menuClick;
    },
    onMenuItemClick(event) {
      this.onMenuToggle();
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static-sidebar-inactive": this.layoutMode === "static",
          "layout-static": !this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
          "layout-theme-light": this.$appState.theme.startsWith("saga"),
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? "images/logo-white.svg" : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
